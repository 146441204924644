import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { Helmet as Head } from "react-helmet"
import { useStyle } from "../hooks/use-style"
import { lightTheme, darkTheme } from "../services/theme"
import { useMedia } from "../hooks/use-media"
import { navigate } from "@reach/router"

const BlogPage: FunctionComponent = ({ location }) => {
  const [article, setArticle] = useState(null)
  const { isTouchDevice } = useMedia()

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    primaryBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.primary,
    inputBackground:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,

    headerBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
  }))

  console.log("path " + location.pathname)
  const fetchArticle = useCallback(async () => {
    console.log("fetchArticle " + location.pathname)
    const parts = location.pathname.split("/")
    let id = ""
    if (parts.length > 2) {
      id = parts[2]
    } else return

    const response = await Promise.resolve(
      fetch("/api/v2/blog/" + id)
        .then(res => res.json())
        .catch(error => {
          console.log("json parsing error occured ", error)
          return null
        })
    )
    if (response) {
      window.navigateTo = (event, id: string) => {
        event.preventDefault()
        if (id == "smartlike.org") navigate("/")
        else navigate("/docs/" + id)
      }
      if ("html" in response) {
        setArticle(response["html"])
      }
    }
  }, [location])

  useEffect(() => {
    console.log("useEffect")
    fetchArticle()
  }, [location])

  return (
    <>
      <Head defer={false}>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
        <meta property="og:description" content="Smartlike p2p donations" />
        <meta property="og:smartlike:account" content="test" />
      </Head>

      <header id="navbar" className="header-navbar">
        <div className="header row">
          <div className="logo">
            <div
              className="poor-mans-logo"
              onClick={() => {
                navigate("/")
              }}
            >
              smartlike
            </div>
          </div>
        </div>
      </header>

      <div
        style={{
          fontFamily: "Roboto, Helvetica Neue, sans-serif",
          marginBottom: "20px",
        }}
      >
        <div className={isTouchDevice ? "m-wrapper" : "wrapper"}>
          {article && (
            <div
              dangerouslySetInnerHTML={{
                __html: article,
              }}
            ></div>
          )}
        </div>
      </div>
      <style jsx global>{`
        h3 {
          font-weight: 500;
          /*text-align: center;*/
          font-size: 28px;
        }
        figure {
          margin: 0;
          text-align: center;
        }
        p,
        li {
          line-height: 28px !important;
          font-size: 18px;
          text-underline-offset: 2px;
          /*font-weight: normal;*/
          font-weight: 400;
          color: rgba(0, 0, 0, 0.84);
        }
        b {
          font-weight: 500 !important;
          color: rgba(0, 0, 0, 0.84);
        }
        body {
          background-color: ${css.primaryBackgroundColor} !important;
        }
      `}</style>

      <style jsx>{`
        h3 {
          text-align: center;
        }

        .poor-mans-logo {
          font-family: "Roboto";
          font-weight: 400;
          padding-left: 20px;
          font-size: 28px;
        }
        .poor-mans-logo:hover {
          cursor: pointer;
        }

        .header-navbar {
          height: 60px;
          position: sticky;
          width: 100%;
          background-color: ${css.headerBackgroundColor};
          display: flex;
          align-items: center;
          top: 0;
          z-index: 4;
          border-bottom: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color;
        }

        .m-header-navbar {
          height: 50px;
          position: fixed;
          width: 100%;
          background-color: ${css.headerBackgroundColor};
          display: flex;
          align-items: center;
          top: 0;
          z-index: 4;
          border-bottom: 1px solid ${css.borderColor};
          transition: 200ms background-color, 200ms border-color;
        }

        .header {
          padding: 0 20px;
          max-width: 1280px;
          align-items: center;
          grid-template-columns: minmax(0, 2fr) minmax(0, 7fr) minmax(0, 3fr);
        }
        .m-header {
          padding: 0 5px;
          width: 100%;
        }
        .row {
          max-width: 1240px;
          width: 100%;
          margin: auto;
          display: grid;
          /*grid-column-gap: 2rem;*/
          align-items: flex-start;
        }

        .wrapper {
          padding: 30px;
          max-width: 770px;
          width: 100%;
          margin: auto;
          background-color: white;
          margin-top: 30px;
          border-radius: 5px;
        }
        .m-wrapper {
          padding: 30px;
          max-width: 770px;
          width: 100%;
          margin: auto;
          background-color: wheat;
        }

        input[type="range"] {
          color: grey;
          width: 100%; /* Specific width is required for Firefox. */
        }

        .form {
          margin: 0 auto;
          padding: 100px 30px 0;
        }

        .input-range {
          margin-bottom: 160px;
        }
        .slidercontainer {
          width: 100%; /* Width of the outside container */
          margin-top: 20px;
          margin-bottom: 40px;
        }
        .slider {
          width: 100px;
        }
        .submitCell {
          width: 100%;
          margin-bottom: 20px;
        }
        .submit {
          width: 90px;
          font-family: "Roboto";
          padding: 2px;

          color: inherit;
          background-color: transparent;
          border: 1px solid ${css.borderColor};
          padding: 5px;
          transition: 200ms border-color, 200ms background-color, 200ms color;
          z-index: 1;
          border-radius: 0.3em;
          background-color: ${css.activeButtonBackgroundColor};
        }
        .submit:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }

        .amountCell {
          display: table-cell;
          vertical-align: top;
          padding-right: 5px;
          width: 100px;
        }
        .amount {
          width: 100%;
          text-align: right;
          font-family: "Roboto";
          font-size: 14px;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          /*min-height: 3.3em;*/
          line-height: 18px;
        }
        .amount:focus,
        .amount:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }

        .header {
          text-align: center;
          font-size: 17px;
          margin-bottom: 20px;
          margin-top: 20px;
          font-weight: 400;
        }

        .donate-header {
          display: inline-flex;
          text-align: center;
          font-size: 17px;
          margin-bottom: 40px;
          margin-top: 20px;
        }

        .generate {
          cursor: pointer;
          /*display: inline;
                margin-left: 20px;*/
          text-decoration: underline;
          /*margin-bottom: 20px;*/
          outline: none;
          user-select: none;
          text-align: left;
        }
        .custom-form {
          margin-top: 30px;
        }

        .text-input {
          width: 100%;
          height: 100%;
          margin: auto;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          color: inherit;
          padding-left: 0.5em;
          padding-right: 0.5em;
          flex: 1;
          font: inherit;
          outline: none;
          height: 32px;
        }
        .text-input:focus,
        .text-input:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }
        ::-webkit-input-placeholder {
          /* Edge */
          opacity: 0.5;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          opacity: 0.5;
        }
        ::placeholder {
          opacity: 0.5;
        }
      `}</style>
    </>
  )
}
export default BlogPage
